
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed, onMounted, watch } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import ConsignmentsTable from './ConsignmentsTable.vue'
import ConsignmentsTableFilter from './ConsignmentsTableFilter.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
import { IFilterSelect } from '@/types/interfaces/IFilterSelect'

export default defineComponent({
  name: 'Consignments',
  components: { SyncOutlined, ConsignmentsTable, ConsignmentsTableFilter },
  setup() {
    const store = useStore()
    const isConsignmentsLoading = computed(() => store.state.consignments.consignmentsLoading)
    const consignmentsExists = computed(() => store.getters['consignments/consignmentsExists'])
    const searchLoading = ref<boolean>(false)
    const searchValue = ref<string | null>(null)
    const filterQuery = ref<IFilterWhereQuery[]>([])
    const filterTags = ref<string[]>([])
    const filterList = ref<IAppDocument<ConsignmentsDocument>[]>([]) // TODO: Make it computed
    const consignmentsList = computed(
      () => store.getters[`consignments/consignmentList`] as IAppDocument<ConsignmentsDocument>[],
    )
    const reloadIconType = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(
        `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENTS_ON_LOAD}`,
        {
          filters: filterQuery.value,
        },
      )
      filterList.value = consignmentsList.value
      reloadIconType.value = false
    }

    const handleSearchEnter = async (trackingId: string | null) => {
      searchLoading.value = true
      if (!trackingId) {
        filterList.value = consignmentsList.value
      }
      const resp: ConsignmentsDocument[] | null = await store.dispatch(
        `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENT_DATA_ID}`,
        {
          trackId: trackingId,
        },
      )
      filterList.value = resp
        ? resp.map((x) => {
            return { id: x.awb, doc: x } as IAppDocument<ConsignmentsDocument>
          })
        : consignmentsList.value

      searchLoading.value = false
    }

    const handleFilterApply = async (filterSelect: IFilterSelect) => {
      if (filterSelect) {
        filterQuery.value = filterSelect.filters
        filterTags.value = filterSelect.filterTags
        await handleDataReload()
      }
    }

    onMounted(() => {
      if (consignmentsList.value.length === 0) {
        handleDataReload()
      } else {
        filterList.value = consignmentsList.value
      }
    })

    watch(searchValue, (newv) => {
      if (!newv) {
        filterList.value = consignmentsList.value
      }
    })

    watch(consignmentsList, (newv, oldv) => {
      if (oldv.length !== newv.length && oldv.length === filterList.value.length) {
        filterList.value = newv
      }
    })

    return {
      isConsignmentsLoading,
      consignmentsExists,
      reloadIconType,
      handleDataReload,
      consignmentsList,
      searchLoading,
      handleSearchEnter,
      filterList,
      searchValue,
      handleFilterApply,
      filterTags,
      filterQuery,
    }
  },
})
