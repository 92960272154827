
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { useStore } from 'vuex'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import * as actions from '@/store/actions.type'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import { useRouter } from 'vue-router'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import * as utils from '@/services/helpers'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'

const columns = [
  {
    title: 'AWB',
    dataIndex: 'doc.awb',
    key: 'awb',
  },
  {
    title: 'RefNo',
    dataIndex: 'doc.rfn',
    key: 'rfn',
  },
  {
    title: 'Customer',
    dataIndex: 'doc.customerId',
    key: 'customerId',
    slots: { customRender: 'customer' },
  },
  {
    title: 'Vendor',
    dataIndex: 'doc.vendorId',
    key: 'vendorId',
    slots: { customRender: 'vendor' },
  },
  {
    title: 'Booking Date',
    dataIndex: 'doc.bookingDate',
    key: 'bookingDate',
    slots: { customRender: 'renderDate' },
  },
  {
    title: 'Dest',
    dataIndex: 'doc.destinationAddress',
    key: 'dest',
    slots: { customRender: 'destination' },
  },
  {
    title: 'Status',
    dataIndex: 'doc.shipmentStatus',
    key: 'shipmentStatus',
  },
  {
    title: 'Pcs',
    dataIndex: 'doc.pcs',
    key: 'pcs',
  },
  {
    title: 'Weight',
    dataIndex: 'doc.weight',
    key: 'weight',
  },
  {
    title: 'Amount',
    dataIndex: 'doc.amount',
    key: 'amount',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'ConsignmentsTable',
  components: { ButtonGroupActions },
  props: {
    consignmentsList: {
      type: Object as PropType<IAppDocument<ConsignmentsDocument>[] | null>,
      required: true,
    },
    filters: {
      type: Object as PropType<IFilterWhereQuery[] | null>,
      required: false,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const dataSource = computed(() => props.consignmentsList)
    const tableLoading = ref(false)
    const actionButtonList = [ActionButtonList.VIEW, ActionButtonList.EDIT, ActionButtonList.DELETE]
    const { getVendorByIdAlias, getCustomerById } = useSearchMapping()
    const getCustomerName = (id: string | null) => {
      if (!id) {
        return 'N/A'
      }
      const cust = getCustomerById(id, null)
      return `${cust?.displayName} (${cust?.cid})`
    }
    const getVendorName = (id: string | null) => {
      if (!id) {
        return 'N/A'
      }
      const cust = getVendorByIdAlias(id, null)
      return `${cust?.displayName}`
    }
    const getDest = (dest: IAddressFormat | null) => {
      return utils.formatDestObject(dest)
    }
    const totalTableRecords = computed(
      () => store.getters['appGlobals/totalConsignments'](false) as number,
    )
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const handleViewClick = (record: any) => {
      console.log('Rec: ', record)
    }

    const handleChange = async (page: any) => {
      tableLoading.value = true
      const required: number = page.current * page.pageSize

      if (dataSource.value && required > dataSource.value.length) {
        const fetchSize = required - dataSource.value.length
        await store.dispatch(
          `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENTS_FROM_SERVER}`,
          {
            fetchSize,
            filters: props?.filters,
          },
        )
      }
      tableLoading.value = false
    }

    const handleEditClick = (record: IAppDocument<any>) => {
      router.replace({ name: 'consignmentEdit', params: { id: record.id } })
    }

    const handleDeleteClick = async (record: IAppDocument<any>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(
        `consignments/${actions.ConsignmentsAction.SET_OR_DELETE_CONSIGNMENT}`,
        docOperation,
      )
      tableLoading.value = false
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: props.filters && props.filters?.length > 0 ? 0 : totalTableRecords.value || 0,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })

    return {
      columns,
      pagination,
      dataSource,
      tableLoading,
      handleChange,
      handleViewClick,
      actionButtonList,
      handleEditClick,
      handleDeleteClick,
      getCustomerName,
      getDest,
      getVendorName,
    }
  },
})
