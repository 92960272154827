<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Consignments</h4>
      </div>
      <div class="d-flex flex-column justify-content-center mr-3">
        <a-tooltip title="Filter">
          <div>
            <ConsignmentsTableFilter @onFilterApply="handleFilterApply" />
          </div>
        </a-tooltip>
      </div>
      <div>
        <router-link
          :to="{ name: 'consignmentEntry' }"
          class="btn btn-success btn-with-addon text-nowrap my-3 mr-3"
        >
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-user-plus"></i>
          </span>
          Entry
        </router-link>
      </div>
      <div>
        <div>
          <router-link
            :to="{ name: 'newInvoice' }"
            class="btn btn-success text-blue btn-with-addon text-nowrap my-3 mr-3"
          >
            <span class="btn-addon">
              <i class="btn-addon-icon fe fe fe-plus"></i>
            </span>
            Invoice
          </router-link>
        </div>
      </div>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'consignments' },
                  }"
                >
                  Import
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isConsignmentsLoading" active>
        <div
          class="text-center my-5"
          v-if="!consignmentsExists && !filterQuery && filterQuery?.length <= 0"
        >
          <h3>Create Consignments here.</h3>
          <p class="text-muted">
            Create and manage your consignments, all in one place.
          </p>
          <div>
            <a-empty :description="false" />
          </div>
          <div>
            <router-link
              :to="{ name: 'consignmentEntry' }"
              class="btn btn-success my-2 text-uppercase"
            >
              Create New Consignment
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-lg-3">
              <div class="mb-4">
                <a-input-search
                  placeholder="Search AWB/RFN"
                  :loading="searchLoading"
                  v-model:value="searchValue"
                  @search="handleSearchEnter"
                  allow-clear
                ></a-input-search>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="d-flex flex-wrap align-items-center mt-1">
                <a-tag v-for="(val, ix) in filterTags" :key="ix" size="large" color="blue">
                  {{ val }}
                </a-tag>
              </div>
            </div>
          </div>
          <div>
            <ConsignmentsTable :consignments-list="filterList" :filters="filterQuery" />
          </div>
        </div>
      </a-skeleton>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed, onMounted, watch } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import ConsignmentsTable from './ConsignmentsTable.vue'
import ConsignmentsTableFilter from './ConsignmentsTableFilter.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
import { IFilterSelect } from '@/types/interfaces/IFilterSelect'

export default defineComponent({
  name: 'Consignments',
  components: { SyncOutlined, ConsignmentsTable, ConsignmentsTableFilter },
  setup() {
    const store = useStore()
    const isConsignmentsLoading = computed(() => store.state.consignments.consignmentsLoading)
    const consignmentsExists = computed(() => store.getters['consignments/consignmentsExists'])
    const searchLoading = ref<boolean>(false)
    const searchValue = ref<string | null>(null)
    const filterQuery = ref<IFilterWhereQuery[]>([])
    const filterTags = ref<string[]>([])
    const filterList = ref<IAppDocument<ConsignmentsDocument>[]>([]) // TODO: Make it computed
    const consignmentsList = computed(
      () => store.getters[`consignments/consignmentList`] as IAppDocument<ConsignmentsDocument>[],
    )
    const reloadIconType = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(
        `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENTS_ON_LOAD}`,
        {
          filters: filterQuery.value,
        },
      )
      filterList.value = consignmentsList.value
      reloadIconType.value = false
    }

    const handleSearchEnter = async (trackingId: string | null) => {
      searchLoading.value = true
      if (!trackingId) {
        filterList.value = consignmentsList.value
      }
      const resp: ConsignmentsDocument[] | null = await store.dispatch(
        `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENT_DATA_ID}`,
        {
          trackId: trackingId,
        },
      )
      filterList.value = resp
        ? resp.map((x) => {
            return { id: x.awb, doc: x } as IAppDocument<ConsignmentsDocument>
          })
        : consignmentsList.value

      searchLoading.value = false
    }

    const handleFilterApply = async (filterSelect: IFilterSelect) => {
      if (filterSelect) {
        filterQuery.value = filterSelect.filters
        filterTags.value = filterSelect.filterTags
        await handleDataReload()
      }
    }

    onMounted(() => {
      if (consignmentsList.value.length === 0) {
        handleDataReload()
      } else {
        filterList.value = consignmentsList.value
      }
    })

    watch(searchValue, (newv) => {
      if (!newv) {
        filterList.value = consignmentsList.value
      }
    })

    watch(consignmentsList, (newv, oldv) => {
      if (oldv.length !== newv.length && oldv.length === filterList.value.length) {
        filterList.value = newv
      }
    })

    return {
      isConsignmentsLoading,
      consignmentsExists,
      reloadIconType,
      handleDataReload,
      consignmentsList,
      searchLoading,
      handleSearchEnter,
      filterList,
      searchValue,
      handleFilterApply,
      filterTags,
      filterQuery,
    }
  },
})
</script>
