
import { computed, defineComponent, ref } from 'vue'
import { FilterOutlined } from '@ant-design/icons-vue'
import { dateFormats } from '@/services/helpers/constants'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { IFilterWhereQuery } from '@/types/interfaces/IFilterWhereQuery'
import { IFilterSelect } from '@/types/interfaces/IFilterSelect'
import { getDayEndDate, GetShortDateFormat } from '@/services/helpers'
import { useStore } from 'vuex'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'

export default defineComponent({
  name: 'ConsignmentsTableFilter',
  components: { FilterOutlined },
  emits: ['onFilterApply', 'onFilterCancel'],
  setup(props, { emit }) {
    const store = useStore()
    const filterDateRange = ref<string[] | null>(null)
    const selectedDateRange = ref<string[] | null>(null)
    const filterVisible = ref(false)
    const filterCustomerId = ref<string | null>(null)
    const selectedCustomerId = ref<string | null>(null)
    const filterQuery = computed(() => {
      const filters: IFilterWhereQuery[] = []
      if (filterDateRange.value) {
        filters.push(
          { fieldName: 'bookingDate', operator: '>=', value: new Date(filterDateRange.value[0]) },
          {
            fieldName: 'bookingDate',
            operator: '<=',
            value: getDayEndDate(filterDateRange.value[1]),
          },
        )
      }
      if (filterCustomerId.value) {
        filters.push({ fieldName: 'customerId', operator: '==', value: filterCustomerId.value })
      }
      return filters
    })

    const { getCustomerById } = useSearchMapping()

    const customersOptions = computed(() => {
      return store.state.customers.customersSearchList?.map((cust: ICustomersSearchMapping) => {
        return {
          value: cust.id,
          label: `${cust.displayName} (${cust.cid})`,
          key: cust.id,
        }
      })
    })

    const toggleFilter = () => {
      filterVisible.value = !filterVisible.value
    }

    const handleFilterCancel = () => {
      selectedDateRange.value = filterDateRange.value
      selectedCustomerId.value = filterCustomerId.value
      toggleFilter()
    }

    const handleFilterApply = async () => {
      filterDateRange.value =
        (selectedDateRange.value || []).length > 0 ? selectedDateRange.value : null
      filterCustomerId.value = selectedCustomerId.value || null
      const flTags: string[] = []
      const dateTag = !filterDateRange.value
        ? ''
        : `${GetShortDateFormat(filterDateRange.value[0])} to ${GetShortDateFormat(
            filterDateRange.value[1],
          )}`
      if (dateTag) {
        flTags.push(dateTag)
      }
      if (filterCustomerId.value) {
        const cust = getCustomerById(filterCustomerId.value, null)
        flTags.push(`${cust?.displayName} (${cust?.cid})`)
      }
      emit('onFilterApply', {
        filters: filterQuery.value,
        filterTags: flTags,
      } as IFilterSelect)
      toggleFilter()
    }

    return {
      handleFilterCancel,
      handleFilterApply,
      customersOptions,
      dateFormats,
      selectedDateRange,
      selectedCustomerId,
      filterVisible,
      toggleFilter,
    }
  },
})
